import React from "react"
import { Link, graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import SEO from "../../components/Global/MetaHead"

import FeedbackHeader from "../../components/Layout/feedback-header"

const FeedbackPage = ({ data }) => (
  <>
    <SEO
      title="Ми Вам Вдячні - Центр Державних Ліцензій України"
      description="Дуже приємно, що вибрали нас. Будь ласка, поділіться думкою про нашу
        роботу."
      image={`https:${data.ogImage.fixed.src}`}
      url="/feedback/"
      lang="uk"
      noindex="noindex"
    />
    <FeedbackHeader />
    <Container className="py-5" as="section">
      <h1 className="">Дякуємо Вам!</h1>
      <p className="">Ви недавно скористалися послугами Центру Ліцензій.</p>
      <p>
        Дуже приємно, що вибрали нас. Будь ласка, поділіться думкою про нашу
        роботу.
      </p>

      <div className="pt-3 pb-2 h4 text-center">Вам все сподобалося?</div>
      <Row>
        <Col xs={6} sm={6} md={6} className="text-center">
          <Button
            as={Link}
            to="/feedback/good/"
            variant="outline-success"
            size="lg"
            block
          >
            <span role="img" aria-labelledby="happy">
              😀
            </span>{" "}
            Так!
          </Button>
        </Col>
        <Col xs={6} sm={6} md={6} className="text-center">
          <Button
            as={Link}
            to="/feedback/bad/"
            variant="outline-danger"
            size="lg"
            block
          >
            <span role="img" aria-labelledby="sad">
              😟
            </span>{" "}
            Ні
          </Button>
        </Col>
      </Row>
    </Container>
  </>
)
export const query = graphql`
  {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default FeedbackPage
